import { render, staticRenderFns } from "./OrganismContentSliderBrochure.vue?vue&type=template&id=db094960&"
import script from "./OrganismContentSliderBrochure.vue?vue&type=script&lang=js&"
export * from "./OrganismContentSliderBrochure.vue?vue&type=script&lang=js&"
import style0 from "./OrganismContentSliderBrochure.vue?vue&type=style&index=0&id=db094960&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonSliderAtomContentSliderBrochure: require('/app/components/atoms/common/slider/AtomContentSliderBrochure.vue').default,MoleculesCommonMoleculeContentSliderBrochure: require('/app/components/molecules/common/MoleculeContentSliderBrochure.vue').default})
